<template>
  <b-card>
    <h2>
      <b-link to="/_teachers/add" class="btn btn-primary btn-sm" v-if="checkPer('teachers|add')">
        <feather-icon icon="UserPlusIcon" size="20" />
        إضافة معلم جديد
      </b-link>
      &nbsp;
      <b-link class="btn btn-info btn-sm" to="/teachers-import" v-if="checkPer('teachers|add')">
        <feather-icon icon="DownloadIcon" size="20" />
        استيراد المعلمين
      </b-link>
      &nbsp;
      <button class="btn btn-secondary btn-sm" @click="barcode()">
        <feather-icon icon="CastIcon" size="20" />
        طباعة بطاقات المعلمين (الباركود)
      </button>
      &nbsp;
      <button class="btn btn-info btn-sm" v-b-modal.devices>
        <feather-icon icon="ServerIcon" size="20" />
        اضافة المعلمين لجهاز البصمة
      </button>
      &nbsp;
      <button class="btn btn-success btn-sm" @click="fp()">
        <feather-icon icon="UserCheckIcon" size="20" />
        هل للمعلم بصمة مرفوعة؟ <span v-if="checkFP == 1">(جاري البحث...)</span>
      </button>
      <img
        src="../../assets/images/loading.svg"
        style="width: 60px; height: 60px"
        alt=""
        v-if="loading"
      />
    </h2>
    <hr />
    <div class="col-12 table-responsive">
      <table class="table table-hover table-bordered table-striped table-sm">
        <thead>
          <th>المعلم</th>
          <th>رقم الهوية</th>
          <th>مجموعة الإعدادات</th>
          <th>خيارات</th>
        </thead>
        <tbody>
          <tr v-for="teacher in teachers" v-bind:key="teacher._id">
            <td>
              {{ teacher.name }}
              <span v-if="fps.includes(teacher.number) && checkFP == 2"  class="btn btn-sm btn-outline-secondary">
                <img
                  :src="require('@/assets/images/finger.png')"
                  style="width: 15px; height: 15px"
                  title="✔ لديه بصمة"
                /> لديه بصمة
              </span>
              <span v-if="!fps.includes(teacher.number) && checkFP == 2" class="btn btn-sm btn-outline-secondary">
                <img
                  :src="require('@/assets/images/x.png')"
                  style="width: 15px; height: 15px"
                  title="✔ ليس لديه بصمة"
                /> ليس لديه بصمة
              </span>
            </td>
            <td>
              {{ teacher.number }}
            </td>
            <td>
              {{ teacher.group_title }}
            </td>
            <td>
              <!-- primary -->
              <b-dropdown text="خيارات" variant="primary" size="sm">
                <b-dropdown-item
                  @click="go('/_teachers/overview/' + teacher.number)"
                >
                  <feather-icon icon="MapIcon" size="20" />
                  نظرة عامة
                </b-dropdown-item>
                <b-dropdown-item @click="go('/_teachers/edit/' + teacher._id)">
                  <feather-icon icon="EditIcon" size="20" />
                  تعديل المعلم
                </b-dropdown-item>
                <b-dropdown-item @click="deleteTeacher(teacher._id)">
                  <feather-icon icon="TrashIcon" size="20" />
                  حذف المعلم
                </b-dropdown-item>
              </b-dropdown>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
        <b-modal id="devices" title="نقل المعلمين لجهاز البصمة" hide-footer size="lg">
            <div class="g">
            اختر المعلمين <a href="javascript:;" @click="selected_students_devices = teachers"><u>اختيار الكل</u></a>
            </div>
            <div style="width: 100%; border: solid 2px #333; padding: 5px; overflow-y: scroll; height: 150px;">
                <div class="form-check" v-for="student in teachers" :key="student._id">
                <label class="form-check-label">
                    <input type="checkbox" class="form-check-input" v-model="selected_students_devices" :value="student">
                    {{ student.name }}
                </label>
                </div>
            </div>
            <br>
            <p>اختر الجهاز المراد النقل له</p>
            <table class="table table-sm table-hover table-bordered">
            <thead>
                <th>
                    الجهاز
                </th>
                <th>
                    نقل اليه
                </th>
            </thead>
            <tbody>
                <tr v-for="n in devices" :key="n._id">
                    <td>
                        {{ n.title }}
                    </td>
                    <td>
                        <button class="btn btn-success btn-sm" @click="movetodevices(n.serial_number)" v-if="!objloading[n._id]">
                            <i class="fa fa-download"></i> نقل الى الجهاز
                        </button>
                        <span v-if="objloading[n._id]">جاري النقل...</span>
                    </td>
                </tr>
            </tbody>
        </table>
        </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardText,
  BLink,
  BDropdown, BModal, VBModal,
  BDropdownItem,
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
const axios = require("axios");
export default {
  components: {
    BCard,
    BCardText,
    BDropdown,
    BDropdownItem,
    BLink,
    BModal
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  data() {
    return {
      selected_students_devices: [],
      devices: [],
      teachers: [],
      loading: true,
      checkFP: 0,
      fps: [],
      size: "col-4",
            objloading: {},
      user: JSON.parse(localStorage.getItem('user')),
    };
  },
  methods: {
    movetodevices(device_id){
        var g = this;
        g.objloading[device_id] = true;
        g.objloading = JSON.parse(JSON.stringify(g.objloading))
        $.post(api + '/user/devices/move-users-to-devices', {
            jwt: this.user.jwt,
            devices: [device_id],
            users: g.selected_students_devices.map(function(a){
                return {
                    name: a.name,
                    number: a.number
                }
            })
        }).then(function(r){
            g.objloading[device_id] = false;
            g.objloading = JSON.parse(JSON.stringify(g.objloading))
            if(r.status != 100){
                alert(r.response)
            }else{
                alert("تم بنجاح", 100)
            }
        }).fail(function(){
            g.objloading[device_id] = false;
            g.objloading = JSON.parse(JSON.stringify(g.objloading))
        })
    },
    fp(){
        var _g = this;
        _g.checkFP = 1;
        axios
          .post(api + "/user/teachers/fps", {
            jwt: _g.user.jwt
          }).then(function(d){
            _g.checkFP = 2;
            _g.fps = d.data.response
          })
    },
    deleteTeacher(id) {
      if (confirm("متأكد من حذف المعلم؟")) {
        var _g = this;
        axios
          .post(api + "/user/teachers/delete", {
            jwt: _g.user.jwt,
            id: id,
          })
          .then(function (r) {
            if (r.data.status == 200) {
              _g.$toast({
                component: ToastificationContent,
                props: {
                  title: "حدث خطأ JWT",
                  icon: "WarningIcon",
                  variant: "danger",
                },
              });
            } else {
              _g.$toast({
                component: ToastificationContent,
                props: {
                  title: "تم الحذف بنجاح",
                  icon: "WarningIcon",
                  variant: "success",
                },
              });
              axios
                .post(api + "/user/teachers/list", {
                  jwt: _g.user.jwt,
                })
                .then(function (r) {
                  if (r.data.status == 200) {
                    _g.$toast({
                      component: ToastificationContent,
                      props: {
                        title: "حدث خطأ JWT",
                        icon: "WarningIcon",
                        variant: "danger",
                      },
                    });
                  } else {
                    _g.teachers = r.data.response;
                    _g.loading = false;
                  }
                })
                .catch(function () {
                  _g.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "حدث خطأ",
                      icon: "WarningIcon",
                      variant: "danger",
                    },
                  });
                });
            }
          })
          .catch(function () {
            _g.$toast({
              component: ToastificationContent,
              props: {
                title: "حدث خطأ",
                icon: "WarningIcon",
                variant: "danger",
              },
            });
          });
      }
    },
    go(url) {
      this.$router.push(url);
    },
    barcode(){
      var g = this;
      var teachers = this.teachers
      var printWindow = window.open("", "");
      printWindow.document.write(
        "<html lang='ar'><head dir='rtl'><title>tahdir.net</title>"
      );

      //Print the Table CSS.
      printWindow.document.write(
        '<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/bootstrap/4.6.2/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous"><style type = "text/css">'
      );
      printWindow.document.write(`
         body
          {
              font-family: Arial;
              font-size: 10pt;
              direction: rtl;
              text-align: right;
          }
          @media print {
              body{
                .col-6:nth-child(1),
                .col-6:nth-child(2),
                .col-6:nth-child(3),
                .col-6:nth-child(4){
                  position: relative;
                  top: 20px;
                }
              }
            }
            /*  ${g.size == 'col-4' ? 'body,html{ width: 21cm; height: 29.7cm; }' : 'body,html{ width: 100%; }'} */
            body,html{ width: 100%; }
          
          td,tr{
            padding: 1px !important;
          }
          /*.${g.size}{
            height: 14.2857143vh;
          }*/
          ${g.size == 'col-4' ? '.col-4{ height: 14.2857143vh; padding: 10px; /*width: 7cm; height: 5cm*/ }' : '.col-6{height: 14.2857143vh; padding: 10px;}'}
        `);
      printWindow.document.write("</style>");
      printWindow.document.write("</head>");
      //Print the DIV contents i.e. the HTML Table.
      printWindow.document.write(`<body>
          <div class='container-fluid'>
            <div class='row'>`);
      var tabletext = "", i=0;
      teachers.forEach(function (teacher) {
        i++
        tabletext =
          tabletext +
          `
              <div class='${g.size}'>
            <div class='card card-body' style='height: 100%; padding: 5px;  border: 2px dashed #222; text-align: center;'>
            <br>
            <table class='table table-bordered'>
              <tbody>
                <tr>
                  <td>
                    <strong style='font-size: 12pt !important'>${
                      teacher.name
                    }</strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img src='https://barcode.tec-it.com/barcode.ashx?data=${teacher.number}&code=Code128'>
                  </td>
                </tr>
                </tr>
              </tbody>
            </table>
            </div>
          </div>
        `;
        if(i%(3*7) == 0 && g.size == "col-4"){
          tabletext = tabletext + `<div style="clear:both!important;"/></div>
<div style="page-break-after:always"></div> 
<div style="clear:both!important;"/> </div>`
        }
      });
      printWindow.document.write(
        `
            ` +
          tabletext 
      );
      printWindow.document.close()
      setTimeout(() => {
      printWindow.print();
      }, 2000);
    }
  },
  created() {
      if(!checkPer("teachers|view")){
          this.$router.push('/NotPermitted')
          return false;
      }
    var _g = this, g = this;
    $.post(api + '/user/devices/all', {
        jwt: this.user.jwt
    }).then(function(r){
        if(r.status != 100){
            alert(r.response)
        }else{
            g.devices = r.response
        }
    }).fail(function(){
    })
    axios
      .post(api + "/user/teachers/list", {
        jwt: _g.user.jwt,
      })
      .then(function (r) {
        if (r.data.status == 200) {
          _g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدث خطأ JWT",
              icon: "WarningIcon",
              variant: "danger",
            },
          });
        } else {
          _g.teachers = r.data.response;
          _g.loading = false;
        }
      })
      .catch(function () {
        _g.$toast({
          component: ToastificationContent,
          props: {
            title: "حدث خطأ",
            icon: "WarningIcon",
            variant: "danger",
          },
        });
      });
  },
};
</script>

<style>
</style>
